import { load } from '@fingerprintjs/botd';
import { mixpanelTrack } from '../utils/mixpanel';
import { DetectionLibraryEnum, MixpanelEventTypes } from '../utils/mixpanel/types';

interface BotDResult {
  bot: boolean;
  botKind?:
    | 'unknown'
    | 'headless_chrome'
    | 'phantomjs'
    | 'nightmare'
    | 'selenium'
    | 'electron'
    | 'nodejs'
    | 'rhino'
    | 'couchjs'
    | 'sequentum'
    | 'slimerjs'
    | 'cefsharp';
}

export const detectBot = async (signUpData: any) => {
  try {
    // Initialize BotD
    const botd = await load();
    const botdResult = botd.detect();

    const result = {
      botdResult: botdResult as BotDResult,
    };

    // Track BotD data in Mixpanel
    mixpanelTrack(MixpanelEventTypes.BOT_DETECTION_DATA, {
      detectionLibrary: DetectionLibraryEnum.BOTD,
      ...botdResult,
      ...signUpData,
    });

    return result;
  } catch (error) {
    console.error('Bot detection error:', error);
    return null;
  }
};

export default detectBot;
