import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { IRootState } from 'src/store';
import {
  UserTagTypesEnum,
  setUserTagsRequest,
} from 'src/store/automations/slices/automationsSlice';

export default function OrdersPageOnboardingDialog() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { isUserBoardedOrdersPage } = useSelector((state: IRootState) => state.automations);
  const { userId } = useSelector((state: IRootState) => state.auth);

  useEffect(() => {
    if (!isUserBoardedOrdersPage) {
      setOpen(true);
    }
  }, [isUserBoardedOrdersPage]);

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleDontShowAgain = () => {
    if (userId) {
      dispatch(
        setUserTagsRequest({
          userId,
          userTagType: UserTagTypesEnum.ONBOARDING_ORDERS_PAGE,
        })
      );
    }
    setOpen(false);
  };

  const handleLearnMore = () => {
    window.open('https://docs.primelister.com/features/cross-lister-tool/auto-delisting', '_blank');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="h4" component="div" sx={{ textAlign: 'center', mb: 1 }}>
          Auto-Delisting Feature ✨
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pb: 2, pt: 0 }}>
          <Typography variant="body1" sx={{ mb: 2 }}>
            When an item is sold, PrimeLister automatically delists listings across your connected
            marketplaces.
          </Typography>

          <Typography variant="body1" sx={{ mb: 3 }}>
            To activate this feature, please enable auto-delisting for your shops in the My Shops
            page.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
            <Button variant="text" color="inherit" onClick={handleDontShowAgain}>
              Don't show again
            </Button>
            <Button variant="contained" onClick={handleLearnMore} sx={{ textTransform: 'none' }}>
              Learn More
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
