import React, { Component, ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Container, Typography, Stack, Box } from '@mui/material';
import { openCrispChat } from 'src/utils/crispChatHelpers';
import { styled } from '@mui/material/styles';
import Iconify from './iconify';
import { SeverityPill } from './severity-pill';
import Tracker from '@openreplay/tracker';
import { connect } from 'react-redux';
import { IRootState } from 'src/store';
import { isLoggedIn } from 'src/utils/isLoggedIn';
import { IS_SANDBOX } from 'src/config';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// Session storage key for reload attempts
const ERROR_RELOAD_ATTEMPTS_KEY = 'errorReloadAttempts';
const MAX_RELOAD_ATTEMPTS = 2;

// Initialize OpenReplay tracker
const openReplayTracker = new Tracker({
  projectKey: import.meta.env.VITE_OPENREPLAY_KEY as string,
  // @ts-ignore
  network: {
    capturePayload: true,
  },
  defaultInputMode: 0,
  obscureTextNumbers: false,
  obscureTextEmails: false,
  obscureInputEmails: false,
  obscureInputDates: false,
});

openReplayTracker.start();

interface Props {
  children: ReactNode;
  isPrimeListerMobileApp: boolean;
}

interface State {
  hasError: boolean;
  error: Error | null;
  reloadAttempts: number;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    reloadAttempts: Number(sessionStorage.getItem(ERROR_RELOAD_ATTEMPTS_KEY) || 0),
  };

  componentDidMount() {
    const { email, loggedIn, proStatus, userId } = isLoggedIn();
    const { isPrimeListerMobileApp } = this.props;

    if (loggedIn && !IS_SANDBOX) {
      openReplayTracker.setUserID(email);
      openReplayTracker.setMetadata('proStatus', proStatus);
      openReplayTracker.setMetadata('customerUserId', userId);
      if (isPrimeListerMobileApp) {
        openReplayTracker.setMetadata('platform', 'MobileApp');
      }
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    const currentAttempts = Number(sessionStorage.getItem(ERROR_RELOAD_ATTEMPTS_KEY) || 0);
    return {
      hasError: true,
      error,
      reloadAttempts: currentAttempts,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);

    Sentry.captureException(error, {
      extra: {
        componentStack: errorInfo.componentStack,
        errorMessage: error.message,
        errorName: error.name,
        reloadAttempts: this.state.reloadAttempts,
      },
    });

    // Track error in OpenReplay
    openReplayTracker.handleError(error);
  }

  private handleRetry = () => {
    const newAttempts = this.state.reloadAttempts + 1;
    sessionStorage.setItem(ERROR_RELOAD_ATTEMPTS_KEY, String(newAttempts));
    this.setState({ hasError: false, error: null, reloadAttempts: newAttempts });
    window.location.reload();
  };

  private handleOpenChat = () => {
    if (window.$crisp) {
      openCrispChat();
    }
  };

  public render() {
    if (this.state.hasError) {
      const showSupportButton = this.state.reloadAttempts >= MAX_RELOAD_ATTEMPTS;

      return (
        <Container>
          <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
            <Typography variant="h3" paragraph>
              Something went wrong!
            </Typography>

            <Typography sx={{ color: 'text.secondary', mb: 5 }}>
              We're sorry for the error. Please try reloading the page
              {showSupportButton ? ' or contact our support team for assistance' : ''}.
            </Typography>

            <SeverityPill color="error" sx={{ mb: 5, py: 2, px: 3 }}>
              {this.state.error?.message || 'Unknown error occurred'}
            </SeverityPill>

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                onClick={this.handleRetry}
                startIcon={<Iconify icon="eva:refresh-fill" />}
              >
                Reload Page
              </Button>

              {showSupportButton && (
                <Button
                  variant="outlined"
                  onClick={this.handleOpenChat}
                  startIcon={<Iconify icon="eva:message-circle-fill" />}
                >
                  Contact Support
                </Button>
              )}
            </Stack>
          </StyledContent>
        </Container>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state: IRootState) => ({
  isPrimeListerMobileApp: state.home.isPrimeListerMobileApp,
});

export default connect(mapStateToProps)(ErrorBoundary);
