import mixpanel from 'mixpanel-browser';
import UAParser from 'ua-parser-js';
import packageJson from '../../../package.json';
import {
  ConnectionFailTypeEnum,
  IMixpanelAutomationStatusData,
  IMixpanelClosetConnectionData,
  IMixpanelEventData,
  IMixpanelMarketplaceConnectionData,
  IMixpanelMobileAppReviewData,
  IMixpanelBotDetectionData,
  MixpanelEventTypes,
} from './types';

const mixpanelTrackConnectionFail = (type: ConnectionFailTypeEnum) => {
  const parser = new UAParser(navigator.userAgent);
  const browser = parser.getResult();
  const { major, name, version } = browser.browser;

  mixpanel.track(MixpanelEventTypes.EXTENSION_CONNECTION_FAILED, {
    _TYPE: type,
    ...(type === ConnectionFailTypeEnum.EXTENSION_REQUIERED
      ? { _EXTENSION_VERSION: sessionStorage.getItem('extensionVersion') }
      : {}),
    _WEB_VERSION: packageJson.version,
    _BROWSER: browser,
    BROWSER_MAJOR: major,
    BROWSER_NAME: name,
    BROWSER_VERSION: version,
    USER_AGENT: browser.ua,
  });
};

const mixpanelClosetRelatedEvent = (
  data: IMixpanelClosetConnectionData,
  event: MixpanelEventTypes
) => {
  const { result, userHandle, password, country, errorText } = data;

  mixpanel.track(event, {
    ...(result ? { result } : {}),
    ...(errorText ? { errorText } : {}),
    userHandle,
    password,
    country,
  });
};

const mixpanelAutomationStatusChanged = (data: IMixpanelAutomationStatusData) => {
  mixpanel.track(MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED, data);
};

const mixpanelHandleMobileAppReview = (data: IMixpanelMobileAppReviewData) => {
  mixpanel.track(MixpanelEventTypes.MOBILE_APP_REVIEW, data);
};

const mixpanelHandleDebugUserAgent = (data: any) => {
  mixpanel.track(MixpanelEventTypes.DEBUG_USER_AGENT, data);
};

const mixpanelHandleBotDetection = (data: IMixpanelBotDetectionData) => {
  mixpanel.track(MixpanelEventTypes.BOT_DETECTION_DATA, data);
};

const mixpanelMarketplaceConnectionEstablished = (data: IMixpanelMarketplaceConnectionData) => {
  const { marketplace, shop } = data;

  mixpanel.track(MixpanelEventTypes.MARKETPLACE_CONNECTION_ESTABLISHED, {
    shop,
    marketplace,
  });
};

export const mixpanelTrack = <T extends MixpanelEventTypes>(
  event: T,
  data: IMixpanelEventData[T]
) => {
  switch (event) {
    case MixpanelEventTypes.EXTENSION_CONNECTION_FAILED:
      mixpanelTrackConnectionFail(data as ConnectionFailTypeEnum);
      break;
    case MixpanelEventTypes.AUTOMATION_ENABLE_STATUS_CHANGED:
      mixpanelAutomationStatusChanged(data as IMixpanelAutomationStatusData);
      break;
    case MixpanelEventTypes.MOBILE_APP_REVIEW:
      mixpanelHandleMobileAppReview(data as IMixpanelMobileAppReviewData);
      break;
    case MixpanelEventTypes.API_CONNECTION_FAILED:
      mixpanelTrackConnectionFail(data as ConnectionFailTypeEnum);
      break;
    case MixpanelEventTypes.MARKETPLACE_CONNECTION_ESTABLISHED:
      mixpanelMarketplaceConnectionEstablished(data as IMixpanelMarketplaceConnectionData);
      break;
    case MixpanelEventTypes.POSHMARK_CLOSET_CONNECT_CLICK:
      mixpanelClosetRelatedEvent(data as IMixpanelClosetConnectionData, event);
      break;
    case MixpanelEventTypes.BOT_DETECTION_DATA:
      mixpanelHandleBotDetection(data as IMixpanelBotDetectionData);
      break;
    case MixpanelEventTypes.DEBUG_USER_AGENT:
      mixpanelHandleDebugUserAgent(data);
  }
};
