import { styled, Theme, alpha } from '@mui/material/styles';
import React from 'react';

interface SeverityPillRootProps {
  theme?: Theme;
  ownerState: {
    color: 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success';
  };
}

const SeverityPillRoot = styled('span')<SeverityPillRootProps>(({ theme, ownerState }) => {
  const backgroundColor = alpha(theme.palette[ownerState.color].main, 0.12);
  const color = theme.palette[ownerState.color].main;

  return {
    alignItems: 'center',
    backgroundColor,
    borderRadius: 12,
    color,
    cursor: 'default',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 2,
    fontWeight: 600,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
  };
});

interface SeverityPillProps {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'warning' | 'success';
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  sx?: any; // MUI system props
}

export const SeverityPill: React.FC<SeverityPillProps> = ({
  color = 'primary',
  children,
  ...other
}) => {
  const ownerState = { color };

  return (
    <SeverityPillRoot ownerState={ownerState} {...other}>
      {children}
    </SeverityPillRoot>
  );
}; 