import { alpha, Link, Stack } from '@mui/material';
import { useSelector } from 'react-redux';

import Label from 'src/components/label/Label';

import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';
import { IRootState } from 'src/store';
import { getMarketplaceDisplayName, Marketplace } from '@beta.limited/primelister';
import QuickActionsMenu from '../../others/QuickActionsMenu';
import { useMemo } from 'react';
import { IOrdersPopoverData } from 'src/pages/dashboard/Orders/types/dataGridInterfaces';

interface DataGridMarketplaceColumnInterface {
  marketplace: Marketplace;
  rowData?: any;
}

export default function DataGridMarketplaceColumn({ rowData }: DataGridMarketplaceColumnInterface) {
  const { dense } = useSelector((state: IRootState) => state.orders);

  const { marketplace } = rowData;
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
      }}
    >
      <Stack
        spacing={dense ? 0.25 : 0.5}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '125px',
          ml: 1,
          py: dense ? '4px' : 0,
        }}
      >
        <Label
          variant="soft"
          sx={{
            color: getMarketplaceColor(marketplace.toLowerCase()),
            backgroundColor: alpha(getMarketplaceColor(marketplace.toLowerCase()), 0.16),
            textTransform: 'none',
            letterSpacing: '0.04rem',
          }}
        >
          {getMarketplaceDisplayName(marketplace as Marketplace)}
        </Label>
      </Stack>
    </Stack>
  );
}
