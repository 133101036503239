import { Marketplace } from '@beta.limited/primelister';
import { IMyShopsSingleDetail } from 'src/pages/dashboard/MyShops/types/shopifyTypes';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import {
  IAPIMarketplaces,
  IMyShopsAPITable,
  officialApiPlatforms,
} from 'src/store/dashboard/slices/myShopsAPITableSlice';
import { IMarketPlaceProps, IMyShopsState } from 'src/store/dashboard/slices/myShopsSlice';

export function isShopify(marketplace: string | IAPIMarketplaces | Marketplace): boolean {
  return marketplace === Marketplace.SHOPIFY;
}

export function isEbay(marketplace: string): boolean {
  return marketplace.toLowerCase().includes('ebay');
}

/**
 * Retrieves the connected eBay shop from the provided myShopsAPITable.
 *
 * @param {IMyShopsAPITable} myShopsAPITable - The table containing all shop details.
 * @returns {IMyShopsSingleDetail} - The connected eBay shop details.
 */
export function getConnectedEbayShop(myShopsAPITable: IMyShopsAPITable): IMyShopsSingleDetail {
  // @ts-ignore
  const shop: IMyShopsSingleDetail = myShopsAPITable.allShops?.find(
    (shop) =>
      shop.marketplace.includes('ebay') && shop.connectionStatus === ConnectionStatus.Connected
  );

  return shop;
}

/**
 * Retrieves the current shop details for a given marketplace from the myShops state.
 *
 * @param {IMyShopsState} myShops - The state containing shop information.
 * @param {Marketplace} marketplace - The marketplace identifier.
 * @returns {IMarketPlaceProps | undefined} - The shop details for the given marketplace, or undefined if not found.
 */
export function getCurrentShopForMarketplace(
  myShops: IMyShopsState,
  marketplace: Marketplace
): IMarketPlaceProps | undefined {
  const shop = myShops[marketplace];
  if (shop && typeof shop === 'object') {
    return shop as IMarketPlaceProps;
  }
  return undefined;
}

/**
 * Converts a marketplace identifier to a general name.
 *
 * @param {Marketplace} marketplace - The marketplace identifier.
 * @returns {string} - The general name of the marketplace.
 */
export function convertMarketplaceToGeneralName(marketplace: Marketplace): string {
  return marketplace.split('-')[0];
}

/**
 * Type guard to check if the given shop is of type IMarketPlaceProps.
 *
 * @param {any} shop - The shop object to check.
 * @returns {shop is IMarketPlaceProps} - True if the shop is of type IMarketPlaceProps, otherwise false.
 */
function isMarketPlaceProps(shop: any): shop is IMarketPlaceProps {
  return shop && typeof shop === 'object' && ('userId' in shop || 'username' in shop);
}

/**
 * Retrieves the shopper ID for a given marketplace from the myShops state.
 *
 * @param {Marketplace} marketplace - The marketplace identifier.
 * @param {IMyShopsState} myShops - The state containing shop information.
 * @returns {string} - The shopper ID, which could be a userId or username depending on the marketplace.
 */
export function getShopperId(
  marketplace: Marketplace,
  myShops: IMyShopsState,
  disableMarketplacePropsCheck?: boolean
): string {
  const shop = myShops[marketplace as Marketplace];

  if (disableMarketplacePropsCheck || isMarketPlaceProps(shop)) {
    const isUserIdMarketplace = [Marketplace.MERCARI, Marketplace.DEPOP].includes(marketplace);
    return isUserIdMarketplace ? String(shop.userId ?? '') : String(shop.username ?? '');
  }

  return '';
}

export function getShopNameAndId(
  marketplace: Marketplace,
  myShops: IMyShopsState
): {
  shopId: string | null;
  shopName: string | null;
} {
  const shop = myShops[marketplace as Marketplace];

  if (isMarketPlaceProps(shop)) {
    return {
      shopId: shop.userId ? String(shop.userId) : null,
      shopName: shop.username ?? null,
    };
  }

  return {
    shopId: null,
    shopName: null,
  };
}

interface IPlatformShop {
  shopId: string | null;
  shopName: string | null;
  marketplace: string;
}

/**
 * Retrieves all platform shops, combining official and non-official shop connections.
 *
 * @param {IMyShopsSingleDetail[]} officialShops - Array of official shop details.
 * @param {IMyShopsState} extensionShops - State containing non-official shop information.
 * @returns {IPlatformShop[]} - Array of platform shops with their IDs, names, and marketplaces.
 */
export function getAllPlatformShops(
  officialShops: IMyShopsSingleDetail[],
  extensionShops: IMyShopsState
): IPlatformShop[] {
  const officialShopConnections = officialApiPlatforms
    .map((platform) => {
      if (!officialShops) return [];
      const shop = officialShops.find(
        (shop) =>
          shop.marketplace === platform && shop.connectionStatus === ConnectionStatus.Connected
      );
      return shop
        ? {
            shopId: null,
            shopName: shop.shopName,
            marketplace: shop.marketplace,
          }
        : null;
    })
    .filter(Boolean) as IPlatformShop[];

  const nonOfficialShopConnections = Object.keys(extensionShops)
    .filter(
      (key) =>
        !officialApiPlatforms.includes(key as unknown as IAPIMarketplaces) &&
        isMarketPlaceProps(extensionShops[key])
    )
    .map((key) => ({
      ...getShopNameAndId(key as unknown as Marketplace, extensionShops),
      marketplace: key,
    }));

  return [...officialShopConnections, ...nonOfficialShopConnections];
}

export const MARKETPLACE_PLATFORMS = [Marketplace.POSHMARK_US, Marketplace.POSHMARK_CA];
