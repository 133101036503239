import React from 'react';
import ManageSubscriptionsDialog from '../../../mainComponents/OrdersPage/others/ManageSubscriptionsDialog';
import DownloadExtensionDialog from '../../../mainComponents/OrdersPage/others/DownloadExtensionDialog';

const OrdersDialogs = () => (
  <>
    <ManageSubscriptionsDialog />
    <DownloadExtensionDialog />
  </>
);

export default OrdersDialogs;
