import { useSelector , useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { IRootState } from 'src/store';
import { ProductTypeEnum } from 'src/components/LayoutComponents/AnnouncementAlert';
import { getAnnouncementsRequest } from 'src/store/dashboard/slices/accountSlice';

const useAnnouncementAlert = (productType: ProductTypeEnum) => {
  const dispatch = useDispatch();
  const { announcements: announcementsList } = useSelector((state: IRootState) => state.account);

  useEffect(() => {
    dispatch(getAnnouncementsRequest());
  }, []);

  const hasAnnouncementAlert = useMemo(() => {
    if (!announcementsList?.announcements?.length) return false;

    return announcementsList.announcements.some(
      (announcement) =>
        Array.isArray(announcement.products) &&
        announcement.products.includes(productType)
    );
  }, [announcementsList, productType]);

  return hasAnnouncementAlert;
};

export default useAnnouncementAlert; 