import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { createUserActionQueueJobRequest } from 'src/store/dashboard/slices/inventorySlice';
import { inventoryEditSelectedItemRequest } from 'src/store/dashboard/slices/inventorySlice';
import { UserAction, Marketplace } from '@beta.limited/primelister';
import {
  getConnectedEbayShop,
  getShopperId,
  isEbay,
} from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import {
  getFilterModelShaped,
  getPlatformName,
} from 'src/pages/dashboard/Inventory/helpers/getFilterModelShaped';
import { ListingSourceStatus } from 'src/pages/dashboard/Inventory/mainComponents/InventoryPage/others/InventoryQuickEditPopover';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';

interface UseDelistFlowProps {
  getSortModel?: () => { orderBy: string; order: string };
  onClose?: () => void;
  selectedListing: any;
  onStatusChange?: (status: string) => void;
}

export const useDelistFlow = ({
  getSortModel,
  onClose,
  selectedListing,
  onStatusChange,
}: UseDelistFlowProps) => {
  const dispatch = useDispatch();
  const [isDelistDialogOpen, setIsDelistDialogOpen] = useState(false);
  const [pendingStatus, setPendingStatus] = useState<string | null>(null);

  const { page, rowsPerPage, searchValue, inventoryFilterModel } = useSelector(
    (state: IRootState) => state.inventory
  );

  const selectedListingId = selectedListing.id ?? selectedListing._id;

  const myShops = useSelector((state: IRootState) => state.myShops);
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);

  const handleDelistCancel = () => {
    setIsDelistDialogOpen(false);
    setPendingStatus(null);
    if (onClose) {
      onClose();
    }
  };

  const handleDelistConfirm = (allListingSources: ListingSourceStatus[]) => {
    const marketplaceRefs = selectedListing.refs;
    const loggedInPlatforms = allListingSources
      .filter((source) => source.loginStatus)
      .map((source) => getPlatformName(source.platformName));

    Object.keys(marketplaceRefs).forEach((marketplace) => {
      if (loggedInPlatforms.includes(marketplace as IPlatforms)) {
        dispatch(
          createUserActionQueueJobRequest({
            action: UserAction.DE_LIST,
            listings: [
              {
                id: marketplaceRefs[marketplace].id,
                image: selectedListing.image,
                title: selectedListing.title,
              },
            ],
            marketplace: marketplace as Marketplace,
            shop: (isEbay(marketplace)
              ? getConnectedEbayShop(myShopsAPITable).shopName
              : getShopperId(marketplace as Marketplace, myShops)) as string,
          })
        );
      }
    });

    if (pendingStatus) {
      const { orderBy, order } = getSortModel?.() || { orderBy: 'added', order: 'desc' };
      dispatch(
        inventoryEditSelectedItemRequest({
          id: selectedListingId,
          editData: {
            status: pendingStatus,
          },
          showCustomMessage: true,
          getData: {
            page,
            rowsPerPage,
            orderBy,
            order,
            filter: getFilterModelShaped(inventoryFilterModel),
            searchValue,
          },
        })
      );
      onStatusChange?.(pendingStatus);
    }

    setIsDelistDialogOpen(false);
    setPendingStatus(null);
    if (onClose) {
      onClose();
    }
  };

  const handleStatusChange = (status: string) => {
    if (status === 'Sold') {
      setPendingStatus(status);
      setIsDelistDialogOpen(true);
      return;
    }

    const { orderBy, order } = getSortModel?.() || { orderBy: 'added', order: 'desc' };
    dispatch(
      inventoryEditSelectedItemRequest({
        id: selectedListingId,
        editData: {
          status,
        },
        showCustomMessage: true,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
    onStatusChange?.(status);

    if (onClose) {
      onClose();
    }
  };

  const getDialogContent = (allListingSources: ListingSourceStatus[]) => {
    const loggedInPlatforms = allListingSources.filter((source) => source.loginStatus);
    const notLoggedInPlatforms = allListingSources.filter((source) => !source.loginStatus);

    return {
      loggedInPlatforms,
      notLoggedInPlatforms,
      hasLoggedInPlatforms: loggedInPlatforms.length > 0,
    };
  };

  return {
    isDelistDialogOpen,
    handleDelistCancel,
    handleDelistConfirm,
    handleStatusChange,
    getDialogContent,
  };
};
