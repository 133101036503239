// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization from './locales';
// components
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import ErrorHandler from './components/ErrorHandler';
import { useEffect , ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  authInitialize,
  getAppVersionsRequest,
  setExtensionVersion,
} from './store/dashboard/slices/authSlice';
import { loadCrispChat, showCrispChat, getUnreadMessageCount } from './utils/crispChatHelpers';
import useResponsive from './hooks/useResponsive';
import { Navigate, useLocation } from 'react-router';
import { PATH_AUTH, PATH_AUTOMATIONS, PATH_DASHBOARD } from './routes/paths';
import { IRootState } from './store';
import moment from 'moment';
import axiosInstance, { setHeader } from './utils/axios';
import { crosslistEndpoints } from './endpoints';
import { isLoggedIn } from './utils/isLoggedIn';
import { setSession } from './auth/utils';
import { BackgroundActionsEnum } from './utils/ExtensionMessageChannel';
import { useSearchParams } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function App(): ReactElement | null {
  const dispatch = useDispatch();
  const isMobile = useResponsive('down', 'sm');
  const { pathname } = useLocation();
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);
  const {
    refreshToken,
    email,
    fullName,
    proStatus,
    blockState,
    userId,
    referrerCode,
    loggedIn,
    createdAt,
  } = isLoggedIn();

  const getExtensionVersion = async () => {
    const data = await window.extension.sendCommand(BackgroundActionsEnum.GET_EXTENSION_VERSION);
    return data;
  };

  // We must check this here because we can't dispatch actions in ExtensionMessageChannel.ts
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const checkExtensionAndGetVersion = async () => {
      if (window.extension?.isConnected) {
        try {
          const data = await getExtensionVersion();
          if (data.result) {
            dispatch(setExtensionVersion(data.result));
            console.info('Extension version:', data.result);

            if (intervalId) {
              clearInterval(intervalId);
              intervalId = null;
            }
          }
        } catch (error) {
          console.error('Error getting extension version:', error);
        }
      }
    };

    checkExtensionAndGetVersion();

    intervalId = setInterval(checkExtensionAndGetVersion, 500);

    // Cleanup function
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  // Initial load
  useEffect(() => {
    dispatch(authInitialize());
    dispatch(getAppVersionsRequest());
    // Only load Crisp without userId on initial load if not authenticated
    if (!isAuthenticated) {
      loadCrispChat(dispatch);
    }

    window.CRISP_READY_TRIGGER = function () {
      let checkInterval = setInterval(() => {
        if (window.$crisp && typeof window.$crisp.get === 'function') {
          const unreadCount = getUnreadMessageCount();
          if (unreadCount > 0 && (isMobile || isPrimeListerMobileApp)) {
            showCrispChat();
          }

          // Crisp is loaded, we can switch to slower checks
          clearInterval(checkInterval);
          checkInterval = setInterval(() => {
            const unreadCount = getUnreadMessageCount();
            if (unreadCount > 0 && (isMobile || isPrimeListerMobileApp)) {
              showCrispChat();
            }
          }, 5000); // Check every 5 seconds after Crisp is loaded
        }
      }, 1000); // Initial checks every 1 second until Crisp is loaded
    };
  }, []);

  // Handle Crisp initialization/reinitialization when auth state changes
  useEffect(() => {
    if (isAuthenticated && userId) {
      loadCrispChat(dispatch, userId);
    }
  }, [isAuthenticated, userId]);

  useEffect(() => {
    if (loggedIn) {
      axiosInstance
        .get(crosslistEndpoints.auth.GET_REFRESH_ACCESS_TOKEN(refreshToken))
        .then((res) => {
          setSession(
            res.data.accessToken,
            res.data.refreshToken,
            email,
            fullName,
            proStatus,
            blockState,
            userId,
            referrerCode,
            createdAt
          );
          setHeader(res.data.accessToken);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    moment.tz.setDefault(userAccountDetail?.timeZone?.name);
  }, [userAccountDetail]);

  useEffect(() => {
    if (isMobile && !pathname.includes(PATH_AUTH.login) && !isPrimeListerMobileApp) {
      document.body.classList.add('is-mobile-web');
    } else {
      document.body.classList.remove('is-mobile-web');
    }
  }, [isMobile, pathname, isPrimeListerMobileApp]);

  const [searchParams] = useSearchParams();
  const extensionId = searchParams.get('extensionId');

  // This check means that if user comes from the extension, we will not redirect them because it breaks our login flow with extension
  // PL-4858
  if (!extensionId) {
    // If user is logged in and trying to access any auth page (PL-4849)
    if (loggedIn && Object.values(PATH_AUTH).includes(pathname)) {
      if (isMobile || isPrimeListerMobileApp) {
        window.location.href = PATH_AUTOMATIONS.root;
        return null;
      }

      window.location.href = PATH_DASHBOARD.root;
      return null;
    }
  }

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <Router />
            <ErrorHandler />
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
