import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Popover,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  inventoryAddToGroupRequest,
  inventoryEditSelectedItemRequest,
  inventoryRemoveGroupsRequest,
  inventoryUpdateTagsRequest,
  setIsGroupAutocompleteOpen,
  setIsInventoryQuickEditPopoverOpen,
  setIsTagsAutocompleteOpen,
  setQuickEditSelectedTags,
  setSelectedGroupToAdd,
  createUserActionQueueJobRequest,
} from 'src/store/dashboard/slices/inventorySlice';
import { getFilterModelShaped, getPlatformName } from '../../../helpers/getFilterModelShaped';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTagUtilities } from '../../../helpers/useTagUtilities';
import {
  DataGridGroupsColumnInterface,
  DataGridTagsColumnInterface,
} from '../../../types/dataGridInterfaces';
import { LabelColor } from 'src/components/label';
import QuickEditPopoverTagsContent from './quick-edit/QuickEditPopoverTagsContent';
import QuickEditPopoverGroupContent from './quick-edit/QuickEditPopoverGroupContent';
import { LoadingButton } from '@mui/lab';
import { IGroupData, ITagData } from 'src/store/dashboard/slices/groupsAndTagsSlice';
import { isEqual } from 'lodash';
import QuickEditPopoverStatusContent from './quick-edit/QuickEditPopoverStatusContent';
import { getMarketplaceDisplayName, Marketplace, UserAction } from '@beta.limited/primelister';
import { getShopperId, isEbay } from '../../ImportTable/importDataGrid/helpers';
import { getConnectedEbayShop } from '../../ImportTable/importDataGrid/helpers';
import { checkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import usePlatformLoginStatusCheck from 'src/hooks/usePlatformLoginStatusCheck';
import { getDisconnectedListings } from '../dataGrid/helpers';
import { IPlatforms } from 'src/store/dashboard/slices/myShopsSlice';
import { useDelistFlow } from 'src/hooks/useDelistFlow';

export enum InventoryQuickEditPopoverEnum {
  GROUP = 'group',
  TAGS = 'tags',
  STATUS = 'status',
}

export interface IInventoryQuickEditPopoverProps {
  currentGroups?: DataGridGroupsColumnInterface['groups'];
  anchorPosition: {
    top: number;
    left: number;
  };
  currentTags?: DataGridTagsColumnInterface['tags'];
  currentStatus?: string;
}

export interface ListingSourceStatus {
  loginStatus: boolean;
  platformName: string;
}

export default function InventoryQuickEditPopover({
  currentGroups,
  anchorPosition,
  currentTags,
  currentStatus,
}: IInventoryQuickEditPopoverProps) {
  const UPDATE_MESSAGE = 'Item successfully updated';
  const dispatch = useDispatch();
  const {
    isInventoryQuickEditPopoverOpen,
    page,
    rowsPerPage,
    sortModel,
    inventoryFilterModel,
    searchValue,
    selectedGroupToAdd,
    selectedQuickEditItemType,
    quickEditSelectedListing,
    loading,
    quickEditSelectedTags,
  } = useSelector((state: IRootState) => state.inventory);
  const [initialGroup, setInitialGroup] = useState<string | null>(null);
  // const [quickEditSelectedTags, setQuickEditSelectedTags] = useState(currentTags);
  const [initialTags, setInitialTags] = useState(currentTags?.map((tag) => tag._id));
  const [resetTimeoutId, setResetTimeoutId] = useState<number | null>(null);
  const [resetTagsTimeoutId, setResetTagsTimeoutId] = useState<number | null>(null);
  const initialGroupRef = useRef<string | null>(null);
  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();
  const [allListingSources, setAllListingSources] = useState<ListingSourceStatus[]>([]);
  const [atLeastOneSourceConnected, setAtLeastOneSourceConnected] = useState(false);

  useEffect(() => {
    const { allListingSources, atLeastOneSourceConnected } = checkListingSourcesLoginStatus(
      [
        {
          ...quickEditSelectedListing,
          marketplaceSources: quickEditSelectedListing.refs,
        },
      ],
      getLoginStatusOfMarketplace
    );

    setAllListingSources(allListingSources);
    setAtLeastOneSourceConnected(atLeastOneSourceConnected);
  }, [quickEditSelectedListing]);

  useEffect(() => {
    if (currentTags) {
      dispatch(setQuickEditSelectedTags(currentTags));
    }
  }, [currentTags]);
  // These to useEffects for initializing the state
  useEffect(() => {
    if (
      isInventoryQuickEditPopoverOpen &&
      selectedQuickEditItemType === InventoryQuickEditPopoverEnum.GROUP
    ) {
      if (resetTimeoutId !== null) {
        clearTimeout(resetTimeoutId);
        setResetTimeoutId(null);
      }

      // Set the initial group to the current group
      const initialGroupId = currentGroups?.[0]?._id || null;
      initialGroupRef.current = initialGroupId; // Store the initial group ID in the ref
      setInitialGroup(initialGroupId);
      dispatch(setSelectedGroupToAdd(initialGroupId));
    }
  }, [isInventoryQuickEditPopoverOpen, currentGroups, selectedQuickEditItemType]);

  useEffect(() => {
    if (
      isInventoryQuickEditPopoverOpen &&
      selectedQuickEditItemType === InventoryQuickEditPopoverEnum.TAGS
    ) {
      // Clear any pending tag resets
      if (resetTagsTimeoutId !== null) {
        clearTimeout(resetTagsTimeoutId);
        setResetTagsTimeoutId(null);
      }

      // Set the initial tags to the current tags
      setInitialTags(currentTags?.map((tag) => tag._id) || []);
    }
  }, [isInventoryQuickEditPopoverOpen, currentTags, selectedQuickEditItemType, resetTagsTimeoutId]);

  // Cleanup function for the component unmount
  useEffect(
    () => () => {
      if (resetTagsTimeoutId !== null) {
        clearTimeout(resetTagsTimeoutId);
      }
    },
    [resetTagsTimeoutId]
  );

  useEffect(
    () =>
      // Cleanup function
      () => {
        if (resetTimeoutId !== null) {
          clearTimeout(resetTimeoutId);
        }
      },
    [resetTimeoutId]
  );

  const { groupsData } = useSelector((state: IRootState) => state.groupsAndTags);

  const handleResetTagsToInitialState = () => {
    const timeoutId = window.setTimeout(() => {
      if (initialTags && currentTags) {
        const initialTagObjects = currentTags.filter((tag) => initialTags.includes(tag._id));
        dispatch(setQuickEditSelectedTags(initialTagObjects));
      } else {
        // If no initial tags, clear the selected tags.
        dispatch(setQuickEditSelectedTags([]));
        setInitialTags([]);
      }
    }, 800);
    setResetTagsTimeoutId(timeoutId); // Save the timeout ID
  };

  const { handleOnChange, handleTagsUpdated } = useTagUtilities();

  const handleUpdateGroup = () => {
    const { orderBy, order } = getSortModel();
    const listingId = quickEditSelectedListing.id;

    const getData = {
      page,
      rowsPerPage,
      orderBy,
      order,
      filter: getFilterModelShaped(inventoryFilterModel),
      searchValue,
    };

    if (selectedGroupToAdd === '') {
      dispatch(
        inventoryRemoveGroupsRequest({
          items: [listingId],
          customMessage: UPDATE_MESSAGE,
          getData,
        })
      );
    } else {
      const groupName = groupsData.find((item) => item.id === selectedGroupToAdd)?.name;

      dispatch(
        inventoryAddToGroupRequest({
          groupId: selectedGroupToAdd,
          items: [listingId],
          groupName,
          customMessage: UPDATE_MESSAGE,
          getData,
        })
      );
    }
  };

  const handleUpdateTags = () => {
    const { orderBy, order } = getSortModel();
    dispatch(
      inventoryUpdateTagsRequest({
        tagIds: quickEditSelectedTags!
          .map((tag) => tag._id)
          .filter((id): id is string => id !== undefined),
        itemId: quickEditSelectedListing.id,
        customMessage: UPDATE_MESSAGE,
        getData: {
          page,
          rowsPerPage,
          orderBy,
          order,
          filter: getFilterModelShaped(inventoryFilterModel),
          searchValue,
        },
      })
    );
    setInitialTags(
      quickEditSelectedTags!.map((tag) => tag._id).filter((id): id is string => id !== undefined)
    );
    handleResetTagsToInitialState();
  };

  const handleClose = () => {
    if (selectedQuickEditItemType === InventoryQuickEditPopoverEnum.GROUP) {
      const timeoutId = window.setTimeout(() => {
        dispatch(setSelectedGroupToAdd(''));
        setInitialGroup(null);
      }, 800);
      setResetTimeoutId(timeoutId); // Save the timeout ID
      dispatch(setIsGroupAutocompleteOpen(false));
    } else if (selectedQuickEditItemType === InventoryQuickEditPopoverEnum.TAGS) {
      handleResetTagsToInitialState();
      dispatch(setIsTagsAutocompleteOpen(false));
    }
    dispatch(setIsInventoryQuickEditPopoverOpen(false));
  };

  const {
    isDelistDialogOpen,
    handleDelistCancel,
    handleDelistConfirm,
    handleStatusChange: handleDelistStatusChange,
    getDialogContent,
  } = useDelistFlow({
    onClose: handleClose,
    getSortModel: () => {
      const { orderBy, order } = getSortModel();
      return { orderBy, order: order || 'desc' };
    },
    selectedListing: quickEditSelectedListing,
  });

  const handleGroupSelect = (selectedGroup: IGroupData | null) => {
    dispatch(setSelectedGroupToAdd(selectedGroup ? selectedGroup.id : ''));
  };

  const handleAction = () => {
    switch (selectedQuickEditItemType) {
      case InventoryQuickEditPopoverEnum.GROUP:
        handleUpdateGroup();
        break;

      case InventoryQuickEditPopoverEnum.TAGS:
        handleUpdateTags();
        break;
      default:
        break;
    }
  };

  const handleTagUnselect = (tagId: string) => {
    const updatedTags = (quickEditSelectedTags || []).filter((tag) => tag._id !== tagId);
    dispatch(setQuickEditSelectedTags(updatedTags));
  };

  const checkIsActionDisabled = () => {
    if (selectedQuickEditItemType === InventoryQuickEditPopoverEnum.GROUP) {
      return (
        selectedGroupToAdd === initialGroupRef.current ||
        (selectedGroupToAdd === '' && initialGroupRef.current === null)
      );
    } else {
      // Check if all selected tags are in the initial tags and vice versa
      const initialTagsIds = initialTags?.map((tag) => tag);
      const selectedTagsIds = quickEditSelectedTags?.map((tag) => tag._id);
      return isEqual(initialTagsIds, selectedTagsIds);
    }
  };

  const handleStatusChange = (status: string) => {
    handleDelistStatusChange(status);
  };

  const myShops = useSelector((state: IRootState) => state.myShops);
  const myShopsAPITable = useSelector((state: IRootState) => state.myShopsAPITable);

  function getSortModel() {
    const orderBy = sortModel.length > 0 ? sortModel[0].field : 'added';
    const order = sortModel.length > 0 ? sortModel[0].sort : 'desc';
    return { orderBy, order };
  }

  const renderQuickEditContent = () => {
    switch (selectedQuickEditItemType) {
      case InventoryQuickEditPopoverEnum.GROUP:
        return (
          <QuickEditPopoverGroupContent
            handleGroupSelect={handleGroupSelect}
            selectedGroupToAdd={selectedGroupToAdd}
          />
        );
      case InventoryQuickEditPopoverEnum.TAGS:
        return (
          <QuickEditPopoverTagsContent
            handleDeleteTag={handleTagUnselect}
            handleTagsUpdated={handleTagsUpdated}
            currentTags={currentTags}
          />
        );
      case InventoryQuickEditPopoverEnum.STATUS:
        return (
          <QuickEditPopoverStatusContent
            selectedStatus={currentStatus!}
            onSelect={handleStatusChange}
          />
        );
      default:
        return null;
    }
  };

  const popoverStyles: SxProps = {
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& .MuiPaper-root': {
      padding: selectedQuickEditItemType !== InventoryQuickEditPopoverEnum.STATUS ? '16px' : '0px',
      width: '100%',
      maxWidth:
        selectedQuickEditItemType === InventoryQuickEditPopoverEnum.STATUS ? '120px' : '350px',
      height: selectedQuickEditItemType === InventoryQuickEditPopoverEnum.STATUS ? '80px' : 'auto',
    },
  };

  const showDialogActions = selectedQuickEditItemType !== InventoryQuickEditPopoverEnum.STATUS;

  return (
    <Popover
      sx={{
        ...popoverStyles,
      }}
      open={isInventoryQuickEditPopoverOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorPosition={anchorPosition}
      anchorReference="anchorPosition"
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={handleClose}
    >
      {renderQuickEditContent()}

      {showDialogActions && (
        <DialogActions
          sx={{
            mt: '8px',
            pr: '0',
          }}
        >
          <Button variant="outlined" onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={checkIsActionDisabled()}
            variant="contained"
            color="primary"
            onClick={handleAction}
          >
            Save
          </LoadingButton>
        </DialogActions>
      )}

      <Dialog open={isDelistDialogOpen} onClose={handleDelistCancel}>
        <DialogTitle>Confirm Delisting</DialogTitle>
        <DialogContent>
          {(() => {
            const { loggedInPlatforms, notLoggedInPlatforms, hasLoggedInPlatforms } =
              getDialogContent(allListingSources);
            return (
              <>
                {loggedInPlatforms.length > 0 && (
                  <>
                    <Typography variant="body1" gutterBottom>
                      {quickEditSelectedListing.title} will be delisted from these platforms:
                    </Typography>
                    <Typography component="div" sx={{ mb: 1 }} color="success.main">
                      {loggedInPlatforms.map((source, index) => (
                        <span key={source.platformName}>
                          {getMarketplaceDisplayName(source.platformName as Marketplace)}
                          {index < loggedInPlatforms.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </Typography>
                  </>
                )}

                {notLoggedInPlatforms.length > 0 && (
                  <>
                    <Typography variant="body1" gutterBottom>
                      Cannot delist from these platforms due to login required:
                    </Typography>
                    <Typography component="div" color="error">
                      {notLoggedInPlatforms.map((source, index) => (
                        <span key={source.platformName}>
                          {getMarketplaceDisplayName(source.platformName as Marketplace)}
                          {index < notLoggedInPlatforms.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </Typography>
                  </>
                )}

                {hasLoggedInPlatforms && (
                  <Typography sx={{ mt: 2 }}>Do you want to continue?</Typography>
                )}
              </>
            );
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelistCancel} color="inherit">
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            onClick={() => handleDelistConfirm(allListingSources)}
            variant="contained"
            color="primary"
            disabled={!allListingSources.some((source) => source.loginStatus)}
          >
            Delist
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Popover>
  );
}
