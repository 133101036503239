import { useEffect, useState } from 'react';
import { SERVER_ERROR_EVENT, type ServerErrorDetail } from '../utils/axios';

export default function ErrorHandler() {
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const handleServerError = (event: Event) => {
      const serverError = event as CustomEvent<ServerErrorDetail>;
      setError(new Error(`Error Message: ${serverError.detail.message}`));
    };

    window.addEventListener(SERVER_ERROR_EVENT, handleServerError);
    
    return () => {
      window.removeEventListener(SERVER_ERROR_EVENT, handleServerError);
    };
  }, []);

  if (error) {
    throw error;
  }

  return null;
} 