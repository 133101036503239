import { Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import {
  DataGridDateColumnInterface,
  IOrdersPopoverData,
} from 'src/pages/dashboard/Orders/types/dataGridInterfaces';
import QuickActionsMenu from '../../others/QuickActionsMenu';
import { useSelector } from 'react-redux';
import { IRootState } from '../../../../../../../store';
import { getDateWithTimeZone } from '../../../../../../../utils/getDateWithTimezone';

export default function DataGridDateColumn({ orderedAt, rowData }: DataGridDateColumnInterface) {
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  const { added, id, refs: marketplaceSources, title, image, marketplace } = rowData;

  const isToday = useMemo(() => moment(orderedAt).isSame(moment(), 'day'), [orderedAt]);

  const { isOrderPageInFullscreenMode } = useSelector((state: IRootState) => state.orders);

  const newPopoverData = useMemo(
    () => ({ id, marketplaceSources, title, image, marketplace, orderLink: rowData.orderLink }),
    [id, marketplaceSources, title, image, marketplace, rowData.orderLink]
  );

  const formatType = isToday ? 'hh:mm A' : 'DD MMM YYYY';
  const formattedDate = useMemo(
    () => getDateWithTimeZone(orderedAt, userAccountDetail?.timeZone?.name, formatType),
    [orderedAt, userAccountDetail?.timeZone?.name, formatType]
  );

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ position: 'relative', height: '100%', alignItems: 'center' }}
    >
      <Tooltip title={moment(orderedAt).format('DD MMM YYYY hh:mm A')} placement="top">
        <Typography variant="subtitle2" sx={{ fontWeight: 'normal', textAlign: 'center' }}>
          {formattedDate}
        </Typography>
      </Tooltip>

      <Stack
        sx={{
          right: !isOrderPageInFullscreenMode ? '-50px' : '32px',
          position: 'absolute',
          minHeight: '100%',
          display: 'flex',
          alignItems: 'center',
          zIndex: 99,
        }}
      >
        <QuickActionsMenu newData={newPopoverData as IOrdersPopoverData} />
      </Stack>
    </Stack>
  );
}
