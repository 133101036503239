import Image from 'src/components/image';
import { DataGridImageColumnInterface } from 'src/pages/dashboard/Inventory/types/dataGridInterfaces';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { DEFAULT_THUMBNAIL_IMAGE } from 'src/config';

export default function DataGridImageColumn({ url }: DataGridImageColumnInterface) {
  const { dense } = useSelector((state: IRootState) => state.inventory);

  let imageStyle = {
    borderRadius: dense ? 0.75 : 1.25,
    width: dense ? 32 : 40,
    minWidth: dense ? 32 : 40,
    height: dense ? 32 : 40,
    backgroundSize: 'cover',
  };

  return (
    <Image
      src={url ? url : DEFAULT_THUMBNAIL_IMAGE}
      disabledEffect
      visibleByDefault
      sx={imageStyle}
      onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
        e.currentTarget.src = DEFAULT_THUMBNAIL_IMAGE;
      }}
    />
  );
}
