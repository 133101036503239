import React from 'react';
import { Formik, Form, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { Stack, Typography, FormControlLabel, Switch, Collapse, Tooltip } from '@mui/material';

import AgeFilter from '../filters/AgeFilter';
import CurrentPriceFilter from '../filters/CurrentPriceFilter';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Automation, EbayAutoSendOfferToLikersRule } from '@beta.limited/primelister';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import Rules, { RuleProps } from './Rules.ebay';
import ShippingOfferWithSelect from '../ShippingOfferWithSelect';
import ShippingOfferWithoutSelect from '../ShippingOfferWithoutSelect';
import { useAutomationPlatform } from 'src/hooks/useAutomationPlatform';
import FilterByCondition from '../filters/FilterByCondition.ebay';
import IncludePersonalMessage from '../filters/IncludePersonalMessage.ebay';

interface OfferToLikersRulesProps {
  initialSettings: EbayAutoSendOfferToLikersRule[];
}

interface FormValues {
  rules: EbayAutoSendOfferToLikersRule[];
}

const ConditionSwitch = ({
  name,
  label,
  index,
  tooltipContent,
}: {
  name: string;
  label: string;
  index: number;
  tooltipContent: string;
}) => {
  const [field, , helpers] = useField(name);
  const dispatch = useDispatch();
  const { hasActiveClosetConnection, activeClosetCredentialsId } = useSelector(
    (state: IRootState) => state.ebayMyShops
  );
  const { automationsSettings, selectedAutomationOption, activeClosetAutomationPlatform, loading } =
    useSelector((state: IRootState) => state.ebayAutomations);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    const fieldName = name.split('.').pop() as 'new' | 'used';

    if (hasActiveClosetConnection) {
      helpers.setValue(checked);

      const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;
      const updatedRules = automationsSettings[
        Automation.AUTO_SEND_OFFER_TO_LIKERS
      ].config.rules.map((rule, i) =>
        i === index
          ? {
              ...rule,
              filters: {
                ...rule.filters,
                condition: {
                  ...rule.filters.condition,
                  [fieldName]: checked,
                  enabled:
                    checked ||
                    (fieldName === 'new'
                      ? rule.filters.condition.used
                      : rule.filters.condition.new),
                },
              },
            }
          : rule
      );

      const dataForUpdating: IDataForUpdateAutomationSettings = {
        settings: {
          [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
            config: {
              rules: updatedRules,
            },
          },
        },
        toastMessage,
        displayName: selectedAutomationOption.displayName,
        activeClosetAutomationPlatform,
        activeClosetCredentialsId,
      };

      dispatch(updateAutomationSettingsRequest(dataForUpdating));
    } else {
      dispatch(setIsConnectionErrorDialogOpen(true));
    }
  };

  return (
    <FormControlLabel
      control={
        <Switch disabled={loading} {...field} checked={field.value} onChange={handleSwitchChange} />
      }
      label={
        <Stack direction="row" alignItems="center" sx={{ textWrap: 'nowrap' }}>
          {label}
          <Tooltip
            enterTouchDelay={0}
            leaveTouchDelay={3000}
            arrow
            title={tooltipContent}
            placement="top"
            sx={{ textAlign: 'center !important' }}
          >
            <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
          </Tooltip>
        </Stack>
      }
      sx={{
        marginLeft: '-10px !important',
      }}
    />
  );
};

const OfferToLikersRules: React.FC<OfferToLikersRulesProps> = ({ initialSettings }) => {
  const defaultRule: EbayAutoSendOfferToLikersRule = {
    filters: {
      ageRange: { enabled: false, min: 0, max: 12 },
      priceRange: { enabled: false, min: 1, max: 2 },
      condition: { enabled: false, new: false, used: false },
    },
    offer: {
      percentage: 5,
      message: {
        enabled: false,
        text: "Glad you're interested! Here's a special offer for you.",
      },
    },
  };

  const validationSchema = Yup.object().shape({
    rules: Yup.array().of(
      Yup.object().shape({
        offer: Yup.object().shape({
          percentage: Yup.number().min(1).max(100).required('Offer percentage is required'),
          message: Yup.string(),
        }),
      })
    ),
  });

  const renderRuleFields = ({
    rule,
    index,
    errors,
    touched,
    isExpanded,
    toggleExpand,
  }: RuleProps) => (
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <ShippingOfferWithoutSelect ruleIndex={index} />
        </Stack>
        <Collapse
          sx={{
            marginTop: '0 !important',
          }}
          in={isExpanded}
        >
          <Stack spacing={1} mt={'20px'}>
            <IncludePersonalMessage ruleIndex={index} />
            <AgeFilter ruleIndex={index} />
            <CurrentPriceFilter ruleIndex={index} />
            <FilterByCondition ruleIndex={index} />
          </Stack>
        </Collapse>
      </Stack>
    );

  const content = (
    <>
      <Typography
        variant="body1"
        sx={{ fontWeight: 600, display: 'flex', alignItems: 'center', mb: '12px' }}
      >
        Offer Rules
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          title="ℹ️ Set up offer rules to customize discounts and filtering options for your eBay listings."
        >
          <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 1 }} />
        </Tooltip>
      </Typography>
      <Rules
        initialRule={defaultRule}
        initialRules={initialSettings.length > 0 ? initialSettings : [defaultRule]}
        validationSchema={validationSchema}
        renderRuleFields={renderRuleFields}
        defaultExpanded={initialSettings.length === 1}
      />
    </>
  );

  return (
    <Formik
      initialValues={{ rules: initialSettings.length > 0 ? initialSettings : [defaultRule] }}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      <Form>{content}</Form>
    </Formik>
  );
};

export default OfferToLikersRules;
