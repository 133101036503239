import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import {
  createPriceUpdateJobRequest,
  createRelistJobRequest,
  createSendOfferJobRequest,
  setActiveNewTaskDialogType,
  setIsCreateNewTaskDialogOpen,
} from 'src/store/automations/slices/myListingsSlice';
import { NewTaskDialogType , TASK_TYPES } from 'src/pages/automations/MyListingsPage/dialogs/CreateNewTaskDialog';
import { useCheckSubscriptionStatus } from 'src/hooks/useCheckSubscriptionStatus';
import { setShouldOpenExpiredSubscriptionDialog } from 'src/store/automations/slices/myClosetSlice';
import UpdatePriceDialogContent from 'src/pages/automations/MyListingsPage/dialogs/contents/UpdatePriceDialogContent';
import SendOfferDialogContent from 'src/pages/automations/MyListingsPage/dialogs/contents/SendOfferDialogContent';
import RelistDialogContent from 'src/pages/automations/MyListingsPage/dialogs/contents/RelistDialogContent';
import { Box, Button, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import { isValidDate } from '../../../helpers/validation';
import {
  DiscountUnit,
  Marketplace,
  ShippingOfferType,
  UpdateJobPriceBase,
  UpdateJobPriceChangeMode,
  UpdateJobPriceChangeType,
  UserAction,
} from '@beta.limited/primelister';
import { ISendOfferShippingOffer } from '../../../types/bulk-action';
import useResponsive from 'src/hooks/useResponsive';
import moment from 'moment';

export const useTaskDialog = () => {
  const dispatch = useDispatch();
  const { activeCloset, hasCloset } = useSelector((state: IRootState) => state.myCloset);
  const { activeClosetAutomationPlatform } = useSelector((state: IRootState) => state.automations);
  const { myListings, selectedListings, isTaskPending, activeNewTaskDialogType } = useSelector(
    (state: IRootState) => state.myListings
  );
  const { userAccountDetail } = useSelector((state: IRootState) => state.account);

  const isMobile = useResponsive('down', 'sm');
  const listings = myListings.filter((listing) => selectedListings.has(listing.id));

  // Price update
  const [isScheduleEnabled, setIsScheduleEnabled] = useState(false);
  const [priceUpdateValue, setPriceUpdateValue] = useState<number | ''>('');
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(null);
  const [isPriceUpdateValueValid, setIsPriceUpdateValueValid] = useState(false);
  const [priceUpdateMethod, setPriceUpdateMethod] = useState<UpdateJobPriceChangeMode>(
    UpdateJobPriceChangeMode.PERCENTAGE
  );
  const [priceUpdateType, setPriceUpdateType] = useState<UpdateJobPriceChangeType>(
    UpdateJobPriceChangeType.DECREASE
  );

  const [discountPercentage, setDiscountPercentage] = useState<number>(10);
  const [shippingOffer, setShippingOffer] = useState<ISendOfferShippingOffer>({
    type: ShippingOfferType.ORIGINAL_COST,
  });
  const [minEarningType, setMinEarningType] = useState<DiscountUnit>(DiscountUnit.FIXED);
  const [isMinNetEarningEnabled, setIsMinNetEarningEnabled] = useState<boolean>(false);
  const [minThreshold, setMinThreshold] = useState<number | ''>('');
  const [isMinThresholdValid, setIsMinThresholdValid] = useState<boolean>(false);
  const [isDiscountPercentageValid, setIsDiscountPercentageValid] = useState<boolean>(true);

  const handleDialogTypeChange = (type: NewTaskDialogType) => {
    dispatch(setActiveNewTaskDialogType(type));
  };

  const handlePriceUpdateValueChange = (value: string | number) => {
    const numericValue = value === '' ? '' : Number(value);
    setPriceUpdateValue(numericValue);
    setIsPriceUpdateValueValid(
      numericValue !== '' &&
        isValidPriceUpdateHelper(numericValue, priceUpdateMethod as unknown as DiscountUnit)
    );
  };

  const handleMinThresholdChange = (value: string | number) => {
    const numericValue = value === '' ? 0 : Number(value); // Ensure numericValue is a number
    setMinThreshold(numericValue);
    const isValid = isMinThresholdValidHelper(numericValue, minEarningType);
    setIsMinThresholdValid(isValid);
    // Update discount percentage validity if necessary
    setIsDiscountPercentageValid(isDiscountPercentageValidHelper(discountPercentage));
  };

  const handleDiscountPercentageChange = (value: number) => {
    setDiscountPercentage(value);
    const isValid = value >= 10 && value <= 80;
    setIsDiscountPercentageValid(isValid);
    // Update min threshold validity if necessary
    setIsMinThresholdValid(isMinThresholdValidHelper(Number(minThreshold), minEarningType));
  };

  const handlePriceUpdateMethodChange = (method: UpdateJobPriceChangeMode) => {
    setPriceUpdateMethod(method);
    setIsPriceUpdateValueValid(
      priceUpdateValue !== '' &&
        isValidPriceUpdateHelper(Number(priceUpdateValue), method as unknown as DiscountUnit)
    );
  };

  const handleClose = (resetDialogType?: boolean) => {
    if (resetDialogType) {
      dispatch(setActiveNewTaskDialogType(NewTaskDialogType.NONE));
    }
    // Reset the state of the dialog to default values
    setIsScheduleEnabled(false);
    setPriceUpdateValue(0);
    setSelectedDateTime(null);
    setIsPriceUpdateValueValid(false);
    setPriceUpdateMethod(UpdateJobPriceChangeMode.PERCENTAGE);
    setDiscountPercentage(10);
    setShippingOffer({
      type: ShippingOfferType.ORIGINAL_COST,
    });
    setMinEarningType(DiscountUnit.FIXED);
    setIsMinNetEarningEnabled(false);
    setMinThreshold(0);
    setIsMinThresholdValid(false);
    setIsDiscountPercentageValid(true);
  };

  const renderDialogContent = () => {
    switch (activeNewTaskDialogType) {
      case NewTaskDialogType.UPDATE_PRICE:
        return (
          <UpdatePriceDialogContent
            priceUpdateType={priceUpdateType}
            setPriceUpdateType={setPriceUpdateType}
            priceUpdateMethod={priceUpdateMethod}
            setPriceUpdateMethod={handlePriceUpdateMethodChange}
            isScheduleEnabled={isScheduleEnabled}
            setIsScheduleEnabled={setIsScheduleEnabled}
            priceUpdateValue={Number(priceUpdateValue)}
            setPriceUpdateValue={handlePriceUpdateValueChange}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            isPriceUpdateValueValid={isPriceUpdateValueValid}
            setIsPriceUpdateValueValid={setIsPriceUpdateValueValid}
          />
        );
      case NewTaskDialogType.SEND_OFFER:
        return (
          <SendOfferDialogContent
            isScheduleEnabled={isScheduleEnabled}
            setIsScheduleEnabled={setIsScheduleEnabled}
            discountPercentage={discountPercentage}
            setDiscountPercentage={handleDiscountPercentageChange}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
            shippingOffer={shippingOffer}
            setShippingOffer={setShippingOffer}
            minEarningType={minEarningType}
            setMinEarningType={setMinEarningType}
            isMinNetEarningEnabled={isMinNetEarningEnabled}
            setIsMinNetEarningEnabled={setIsMinNetEarningEnabled}
            minThreshold={Number(minThreshold)}
            setMinThreshold={handleMinThresholdChange}
            setIsMinThresholdValid={setIsMinThresholdValid}
            setIsDiscountPercentageValid={setIsDiscountPercentageValid}
          />
        );
      case NewTaskDialogType.RE_LIST:
        return (
          <RelistDialogContent
            isScheduleEnabled={isScheduleEnabled}
            setIsScheduleEnabled={setIsScheduleEnabled}
            selectedDateTime={selectedDateTime}
            setSelectedDateTime={setSelectedDateTime}
          />
        );
      default:
        return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginBottom: '24px',
              marginTop: '8px',
            }}
          >
            {TASK_TYPES.map((type) => (
              <Button
                key={type.value}
                variant="outlined"
                onClick={() => handleDialogTypeChange(type.value)}
                sx={{
                  minWidth: '64px',
                  height: '48px',
                  borderRadius: '8px',
                  textTransform: 'none',
                  color: '#212B36',
                  width: '100%',
                  textAlign: 'left',
                  justifyContent: 'left',
                  gap: '8px',
                  borderColor: 'rgba(145, 158, 171, 0.32)',
                  padding: 'inherit 24px',
                  '&:hover': {
                    color: '#2065D1',
                  },
                }}
              >
                <Iconify icon={type.icon} sx={{ width: 24, height: 24 }} />
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 700,
                    lineHeight: '26px',
                  }}
                >
                  {type.label}
                </Typography>
              </Button>
            ))}
          </Box>
        );
    }
  };

  /**
   * Validates if the discount percentage is within the valid range.
   *
   * @param {number} discountPercentage - The discount percentage to validate.
   * @returns {boolean} - Returns true if the discount percentage is between 10 and 80, inclusive.
   */
  const isDiscountPercentageValidHelper = (discountPercentage: number) =>
    discountPercentage >= 10 && discountPercentage <= 80;

  /**
   * Validates if the minimum threshold value is within the valid range based on the earning type.
   *
   * @param {number} minThresholdValue - The minimum threshold value to validate.
   * @param {DiscountUnit} minEarningType - The type of earning (FIXED or PERCENTAGE).
   * @returns {boolean} - Returns true if the minimum threshold value is valid for the given earning type.
   */
  const isMinThresholdValidHelper = (minThresholdValue: number, minEarningType: DiscountUnit) => {
    if (minThresholdValue === 0) {
      return false; // Ensure empty input is considered invalid
    }
    if (minEarningType === DiscountUnit.FIXED) {
      return minThresholdValue >= 0 && minThresholdValue <= 9999;
    }
    if (minEarningType === DiscountUnit.PERCENTAGE) {
      return minThresholdValue >= 20 && minThresholdValue <= 90;
    }
    return false;
  };

  /**
   * Validates if the price update value is within the valid range based on the update method.
   *
   * @param {number} value - The price update value to validate.
   * @param {DiscountUnit} method - The method used for price update (FIXED or PERCENTAGE).
   * @returns {boolean} - Returns true if the price update value is valid for the given method.
   */
  const isValidPriceUpdateHelper = (value: number, method: DiscountUnit) => {
    if (method === DiscountUnit.PERCENTAGE) {
      return value >= 1 && value <= 100;
    } else {
      return value >= 1 && value <= 99999;
    }
  };

  /**
   * Determines if the button should be disabled based on the task type and provided parameters.
   * @returns {boolean} - Returns true if the button should be disabled, otherwise false.
   */
  const isButtonDisabledHelper = (
    type: NewTaskDialogType,
    params: {
      isScheduleEnabled: boolean;
      isPriceUpdateValueValid: boolean;
      selectedDateTime: Date | null;
      priceUpdateMethod: string;
      priceUpdateValue: number;
      discountPercentage: number;
      isMinNetEarningEnabled: boolean;
      isDiscountPercentageValid: boolean;
      isMinThresholdValid: boolean;
    }
  ) => {
    const {
      isScheduleEnabled,
      isPriceUpdateValueValid,
      selectedDateTime,
      priceUpdateMethod,
      priceUpdateValue,
      discountPercentage,
      isMinNetEarningEnabled,
      isDiscountPercentageValid,
      isMinThresholdValid,
    } = params;

    switch (type) {
      case NewTaskDialogType.UPDATE_PRICE:
        if (isScheduleEnabled) {
          return !(
            isPriceUpdateValueValid &&
            priceUpdateValue &&
            isValidPriceUpdateHelper(priceUpdateValue, priceUpdateMethod as DiscountUnit) &&
            isValidDate(selectedDateTime)
          );
        }
        return (
          !priceUpdateValue ||
          !isPriceUpdateValueValid ||
          !isValidPriceUpdateHelper(priceUpdateValue, priceUpdateMethod as DiscountUnit)
        );
      case NewTaskDialogType.SEND_OFFER:
        const minThresholdValue = Number(minThreshold);
        const isDiscountValid = isDiscountPercentageValidHelper(discountPercentage);
        const isDateValid = isValidDate(selectedDateTime);
        const isMinThresholdValidHelperResult = isMinThresholdValidHelper(
          minThresholdValue,
          minEarningType
        );

        if (isMinNetEarningEnabled && isScheduleEnabled) {
          return !(
            isDiscountValid &&
            isDateValid &&
            isDiscountPercentageValid &&
            isMinThresholdValid &&
            isMinThresholdValidHelperResult
          );
        }

        if (isMinNetEarningEnabled) {
          return !(
            isDiscountPercentageValid &&
            isMinThresholdValid &&
            isMinThresholdValidHelperResult
          );
        }

        if (isScheduleEnabled) {
          return !(isDiscountValid && isDateValid && isDiscountPercentageValid);
        }

        return !isDiscountValid;
      case NewTaskDialogType.RE_LIST:
        if (isScheduleEnabled) {
          return !isValidDate(selectedDateTime);
        }
        return false;
      default:
        return false;
    }
  };

  const getDialogOptions = () => {
    const commonParams = {
      isScheduleEnabled,
      isPriceUpdateValueValid,
      selectedDateTime,
      priceUpdateMethod,
      priceUpdateValue: Number(priceUpdateValue), // Ensure it's a number
      discountPercentage,
      isMinNetEarningEnabled,
      isDiscountPercentageValid,
      isMinThresholdValid,
    };

    switch (activeNewTaskDialogType) {
      case NewTaskDialogType.UPDATE_PRICE:
        return {
          title: 'Update Price',
          confirmButtonText: 'Update Price(s)',
          isButtonDisabled: isButtonDisabledHelper(NewTaskDialogType.UPDATE_PRICE, commonParams),
          isButtonLoading: isTaskPending,
          onConfirm: () => {
            dispatch(
              createPriceUpdateJobRequest({
                action: UserAction.UPDATE,
                listings: listings.map((item) => ({
                  id: item.id,
                  image: item.thumbnailUrl ?? '',
                  title: item.title,
                })),
                marketplace: activeClosetAutomationPlatform as Marketplace,
                shop: activeCloset?.marketplaceUserName,
                isMobile,
                ...(isScheduleEnabled && {
                  targetTime: moment(selectedDateTime)?.valueOf(),
                }),
                priceUpdate: {
                  price: Number(priceUpdateValue),
                  settings: {
                    changeType: priceUpdateType,
                    changeMode: priceUpdateMethod,
                  },
                },
              })
            );
            handleClose();
          },
        };
      case NewTaskDialogType.SEND_OFFER:
        return {
          title: 'Send Offer',
          confirmButtonText: 'Send Offer(s)',
          isButtonDisabled: isButtonDisabledHelper(NewTaskDialogType.SEND_OFFER, commonParams),
          isButtonLoading: isTaskPending,
          onConfirm: () => {
            dispatch(
              createSendOfferJobRequest({
                action: UserAction.OFFER,
                listings: listings.map((item) => ({
                  id: item.id,
                  image: item.thumbnailUrl ?? '',
                  title: item.title,
                })),
                marketplace: activeClosetAutomationPlatform as Marketplace,
                shop: activeCloset?.marketplaceUserName,
                isMobile,
                ...(isScheduleEnabled && { targetTime: moment(selectedDateTime)?.valueOf() }),
                sendOffer: {
                  settings: {
                    discount: { unit: DiscountUnit.PERCENTAGE, value: discountPercentage },
                    shippingOffer: shippingOffer,
                    ...(isMinNetEarningEnabled && {
                      earningFilter: {
                        type: minEarningType,
                        value: Number(minThreshold),
                      },
                    }),
                  },
                },
              })
            );
            handleClose();
          },
        };
      case NewTaskDialogType.RE_LIST:
        return {
          title: 'Re-list',
          confirmButtonText: 'Re-list',
          isButtonDisabled: isButtonDisabledHelper(NewTaskDialogType.RE_LIST, commonParams),
          isButtonLoading: isTaskPending,
          onConfirm: () => {
            dispatch(
              createRelistJobRequest({
                action: UserAction.RELIST,
                isMobile,
                listings: listings.map((item) => ({
                  id: item.id,
                  image: item.thumbnailUrl ?? '',
                  title: item.title,
                })),
                marketplace: activeClosetAutomationPlatform as Marketplace,
                shop: activeCloset?.marketplaceUserName,
                ...(isScheduleEnabled && { targetTime: moment(selectedDateTime)?.valueOf() }),
              })
            );
            handleClose();
          },
        };
      default:
        return {
          title: 'Create new task',
        };
    }
  };

  return {
    isScheduleEnabled,
    setIsScheduleEnabled,
    priceUpdateValue,
    setPriceUpdateValue,
    selectedDateTime,
    setSelectedDateTime,
    isPriceUpdateValueValid,
    setIsPriceUpdateValueValid,
    priceUpdateMethod,
    setPriceUpdateMethod,
    discountPercentage,
    setDiscountPercentage,
    shippingOffer,
    setShippingOffer,
    minEarningType,
    setMinEarningType,
    isMinNetEarningEnabled,
    setIsMinNetEarningEnabled,
    minThreshold,
    setMinThreshold,
    isMinThresholdValid,
    setIsMinThresholdValid,
    isDiscountPercentageValid,
    setIsDiscountPercentageValid,
    handleDialogTypeChange,
    handlePriceUpdateValueChange,
    handleClose,
    listings,
    renderDialogContent,
    getDialogOptions,
  };
};
