import { Container, Grid, Stack, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/store';
import OrdersCard from './mainComponents/OrdersCard';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OrdersPageOnboardingDialog from './components/OrdersPageOnboardingDialog';

export default function OrdersPage() {
  const { isOrderPageInFullscreenMode, loading, ordersCount } = useSelector(
    (state: IRootState) => state.orders
  );
  const navigate = useNavigate();

  // Clean up query parameters when component unmounts
  useEffect(
    () => () => {
      // Remove all query parameters while keeping the base path
      navigate(window.location.pathname, { replace: true });
    },
    [navigate]
  );

  return (
    <>
      <Helmet>
        <title>Orders | PrimeLister</title>
      </Helmet>

      <Container disableGutters={true} maxWidth={!isOrderPageInFullscreenMode ? 'xl' : false}>
        {!isOrderPageInFullscreenMode && (
          <Stack
            direction="row"
            sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '32px' }}
          >
            <Typography variant="h4">Orders</Typography>
          </Stack>
        )}
        <Grid item xs={12}>
          <OrdersCard />
        </Grid>
      </Container>

      <OrdersPageOnboardingDialog />
    </>
  );
}
