import { useDispatch, useSelector } from 'react-redux';
import {
  orderDetailRequest,
  delistOrderRequest,
  setIsManageSubscriptionsDialogOpen,
  setManageSubscriptionDialogData,
  setDownloadExtensionDialogOpen,
} from 'src/store/dashboard/slices/ordersSlice';
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { IRootState } from 'src/store';
import Label from 'src/components/label';
import { getMarketplaceDisplayName, Marketplace, TaskResult } from '@beta.limited/primelister';
import { IOrderItemMarketplace, IOrderTaskRecord } from '../../../types/dataGridInterfaces';
import { LoadingButton } from '@mui/lab';
import useCheckPermission, { PermissionCheckActionEnum } from 'src/hooks/useCheckPermission';
import { alpha } from '@mui/material/styles';
import { getMarketplaceColor } from 'src/pages/dashboard/Tasks/helpers/getMarketplaceColor';
import { getShopperId } from 'src/pages/dashboard/Inventory/mainComponents/ImportTable/importDataGrid/helpers';
import { checkListingSourcesLoginStatus } from 'src/utils/checkListingSourcesLoginStatus';
import usePlatformLoginStatusCheck from 'src/hooks/usePlatformLoginStatusCheck';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

// Status type definition
enum DelistStatusEnum {
  SUCCESS = 'Succeeded',
  FAILED = 'Failed',
  NOT_SUPPORTED = 'Not Supported',
  IN_PROGRESS = 'Delist Processing',
  ACTION_REQUIRED = 'Action Required',
}

const MULTI_ITEM_LISTING_ERROR =
  'This listing is a multi-item listing. So we cannot continue processing.';
const MULTI_ITEM_LISTING_MESSAGE =
  'Since this is a multi-item listing, you’ll need to manually handle removing items from the sale. We can’t automatically adjust the quantities. You need to click “View Item” and update the listing accordingly.';
const GENERIC_DELIST_ERROR_MESSAGE =
  "We tried to auto-delist on the marketplace but couldn't get it to work. You'll need to delist manually, or you can try the delist button again.";

const isMultiItemListing = (taskRecord: IOrderTaskRecord | null): boolean =>
  !!taskRecord?.errorText?.includes('Since this is a multi-item listing');

interface NotLoggedInToDelistDialogProps {
  open: boolean;
  onClose: () => void;
  onReload: () => void;
  marketplaceName: string;
}

function NotLoggedInToDelistDialog({
  open,
  onClose,
  onReload,
  marketplaceName,
}: NotLoggedInToDelistDialogProps) {
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Login Required
      </DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ErrorIcon sx={{ fontSize: '5rem', alignSelf: 'center', mb: '1.5rem' }} color="warning" />
        <DialogContentText sx={{ textAlign: 'center' }}>
          <Typography mb={'1rem'}>
            Sorry, it looks like you're not currently logged in to {marketplaceName}. To delist this
            item from the platform, please sign in.
          </Typography>
          <Typography>After logging into the platform, please reload the page. </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" color="primary" onClick={onReload}>
          Reload Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type StatusColor = 'success' | 'error' | 'info' | 'warning';

interface StatusConfig {
  color: StatusColor;
  tooltip: string | JSX.Element;
}

const getStatusConfig = (
  status: DelistStatusEnum,
  taskRecord: IOrderTaskRecord | null
): StatusConfig => {
  const configs: Record<DelistStatusEnum, StatusConfig> = {
    [DelistStatusEnum.SUCCESS]: {
      color: 'success',
      tooltip: 'We successfully auto-delisted your listing from this marketplace.',
    },
    [DelistStatusEnum.FAILED]: {
      color: 'error',
      tooltip: (
        <>
          {GENERIC_DELIST_ERROR_MESSAGE}
          {taskRecord?.errorText && (
            <Box sx={{ mt: 1 }}>Error Message: {taskRecord.errorText}</Box>
          )}
        </>
      ),
    },
    [DelistStatusEnum.ACTION_REQUIRED]: {
      color: 'warning',
      tooltip: MULTI_ITEM_LISTING_MESSAGE,
    },
    [DelistStatusEnum.NOT_SUPPORTED]: {
      color: 'info',
      tooltip:
        "We don't support an auto-delist feature on this marketplace, so you'll need to delist manually.",
    },
    [DelistStatusEnum.IN_PROGRESS]: {
      color: 'info',
      tooltip: 'Task is being processed in the background. Refresh the page to see updates.',
    },
  };

  return configs[status];
};

const OrderDetailPanel = ({ row }: { row: any }) => {
  const dispatch = useDispatch();
  const [processingItems, setProcessingItems] = useState<string[]>([]);
  const [isNotLoggedInDialogOpen, setIsNotLoggedInDialogOpen] = useState(false);
  const [selectedMarketplace, setSelectedMarketplace] = useState('');
  const { getLoginStatusOfMarketplace } = usePlatformLoginStatusCheck();
  const orderDetail = useSelector(
    (state: IRootState) =>
      state.orders.orderDetails[row.id] ?? {
        loading: false,
        error: null,
        data: [],
      }
  );

  // Get loading state from redux
  const isLoading = useSelector((state: IRootState) => state.orders.loading);
  const orders = useSelector((state: IRootState) => state.orders.orders);
  const myShops = useSelector((state: IRootState) => state.myShops);

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (!orderDetail.data?.length && !orderDetail.loading) {
      dispatch(orderDetailRequest({ id: row.id }));
    }
  }, [row.id]);

  const handlePageReload = () => {
    window.location.reload();
  };

  if (orderDetail?.loading) {
    return (
      <Box sx={{ p: 2, pl: 16, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (orderDetail?.error) {
    return (
      <Box sx={{ p: 2, pl: 16 }}>
        <Typography color="error">Error loading details: {orderDetail.error}</Typography>
      </Box>
    );
  }

  if (!orderDetail?.data?.length || orderDetail.data.every((item) => !item.items?.length)) {
    return (
      <Box sx={{ p: 1, pl: 16, display: 'flex', justifyContent: 'center' }}>
        <Typography color="text.secondary" fontSize={14}>
          There is nothing in your inventory related to this order.
        </Typography>
      </Box>
    );
  }

  const getDelistStatus = (marketplaceItem: IOrderItemMarketplace): DelistStatusEnum => {
    if (processingItems.includes(marketplaceItem.id)) return DelistStatusEnum.IN_PROGRESS;
    if (marketplaceItem.marketplace === Marketplace.FACEBOOK) return DelistStatusEnum.NOT_SUPPORTED;
    if (marketplaceItem.taskRecord?.error) {
      if (isMultiItemListing(marketplaceItem.taskRecord)) {
        return DelistStatusEnum.ACTION_REQUIRED;
      }
      return DelistStatusEnum.FAILED;
    }
    if (!marketplaceItem.taskRecord) return DelistStatusEnum.FAILED;
    return DelistStatusEnum.SUCCESS;
  };

  const handleDelist = async (marketplaceItem: IOrderItemMarketplace) => {
    try {
      if (!window.extension.isConnected) {
        dispatch(setDownloadExtensionDialogOpen(true));
        return;
      }
      setProcessingItems((prev) => [...prev, marketplaceItem.id]);
      const { allListingSources, atLeastOneSourceConnected } = checkListingSourcesLoginStatus(
        [
          {
            _id: marketplaceItem.id,
            id: marketplaceItem.id,
            title: orderDetail.data[0].title,
            image: orderDetail.data[0].thumbnail,
            sku: '',
            refs: {
              [marketplaceItem.marketplace]: {
                id: marketplaceItem.id,
                platform: marketplaceItem.marketplace,
                listingLink: marketplaceItem.marketplaceLink,
                added: marketplaceItem.added,
                updated: marketplaceItem.updated,
              },
            },
            marketplaceSources: {
              [marketplaceItem.marketplace]: {
                id: marketplaceItem.id,
                platform: marketplaceItem.marketplace,
                listingLink: marketplaceItem.marketplaceLink,
                added: marketplaceItem.added,
                updated: marketplaceItem.updated,
              },
            },
            groups: [],
            tags: [],
          },
        ],
        getLoginStatusOfMarketplace
      );

      if (!atLeastOneSourceConnected) {
        setSelectedMarketplace(getMarketplaceDisplayName(marketplaceItem.marketplace));
        setIsNotLoggedInDialogOpen(true);
        setProcessingItems((prev) => prev.filter((id) => id !== marketplaceItem.id));
        return;
      }
      await checkPermission(marketplaceItem.marketplace, PermissionCheckActionEnum.DELIST);

      const shopId = getShopperId(marketplaceItem.marketplace as Marketplace, myShops);

      dispatch(
        delistOrderRequest({
          marketplace: marketplaceItem.marketplace,
          shop: shopId,
          orderId: row.orderId,
          listings: [
            {
              id: marketplaceItem.id,
              image: orderDetail.data[0].thumbnail ?? '',
              title: orderDetail.data[0].title ?? '',
            },
          ],
        })
      );
    } catch (error) {
      setProcessingItems((prev) => prev.filter((id) => id !== marketplaceItem.id));
      dispatch(setManageSubscriptionDialogData(error));
      dispatch(setIsManageSubscriptionsDialogOpen(true));
    }
  };

  return (
    <Box sx={{ p: 2, pl: 16 }}>
      <NotLoggedInToDelistDialog
        open={isNotLoggedInDialogOpen}
        onClose={() => setIsNotLoggedInDialogOpen(false)}
        onReload={handlePageReload}
        marketplaceName={selectedMarketplace}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '4fr 1.2fr 1.2fr 0.8fr',
          gap: 0.5,
          mb: 1,
          px: 1,
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ fontSize: '0.95rem', fontWeight: 600 }}
        >
          Name
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Marketplace
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Delist Status
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          Actions
        </Typography>
      </Box>

      {orderDetail.data.flatMap((item) =>
        item.items.map((marketplaceItem: IOrderItemMarketplace) => {
          const status = getDelistStatus(marketplaceItem);
          const statusConfig = getStatusConfig(status, marketplaceItem.taskRecord);
          const isProcessing = processingItems.includes(marketplaceItem.id);
          const showDelistButton =
            status !== DelistStatusEnum.SUCCESS && status !== DelistStatusEnum.NOT_SUPPORTED;
          const isDelistButtonDisabled =
            isProcessing || isMultiItemListing(marketplaceItem.taskRecord);

          return (
            <Box
              key={marketplaceItem.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: '4fr 1.2fr 1.2fr 0.8fr',
                gap: 0.5,
                alignItems: 'center',
                py: 0.75,
                px: 1,
                '&:hover': {
                  bgcolor: 'rgba(145, 158, 171, 0.08)',
                },
              }}
            >
              <Tooltip title={item.title || ''} arrow>
                <Typography
                  noWrap
                  sx={{
                    maxWidth: 'fit-content',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  {item.title || 'N/A'}
                </Typography>
              </Tooltip>
              <Stack spacing={0.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Tooltip title={marketplaceItem.taskRecord?.name || ''} arrow>
                  <Label
                    variant="soft"
                    sx={{
                      color: getMarketplaceColor(marketplaceItem.marketplace.toLowerCase()),
                      backgroundColor: alpha(
                        getMarketplaceColor(marketplaceItem.marketplace.toLowerCase()),
                        0.16
                      ),
                      textTransform: 'none',
                      letterSpacing: '0.04rem',
                      width: 'fit-content',
                    }}
                  >
                    {getMarketplaceDisplayName(marketplaceItem.marketplace)}
                  </Label>
                </Tooltip>
              </Stack>

              <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                <Tooltip title={statusConfig.tooltip} arrow>
                  <Label
                    color={statusConfig.color}
                    sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                  >
                    {(status === DelistStatusEnum.FAILED || status === DelistStatusEnum.ACTION_REQUIRED) && (
                      <InfoIcon sx={{ fontSize: 16 }} />
                    )}
                    {status}
                  </Label>
                </Tooltip>
              </Box>

              <Stack
                direction="row"
                spacing={1}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                {showDelistButton && !isMultiItemListing(marketplaceItem.taskRecord) && (
                  <LoadingButton
                    size="small"
                    variant="contained"
                    color="primary"
                    loading={isLoading}
                    disabled={isProcessing}
                    onClick={() => handleDelist(marketplaceItem)}
                  >
                    Delist
                  </LoadingButton>
                )}
                <Button
                  size="small"
                  variant="soft"
                  color="inherit"
                  sx={{ whiteSpace: 'nowrap' }}
                  onClick={() =>
                    marketplaceItem.marketplaceLink &&
                    window.open(marketplaceItem.marketplaceLink, '_blank')
                  }
                >
                  View Item
                </Button>
              </Stack>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default OrderDetailPanel;
