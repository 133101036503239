import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';

import { IRootState } from 'src/store';
import { setOpenDownloadExtensionDialog } from 'src/store/dashboard/slices/inventorySlice';
import ErrorIcon from '@mui/icons-material/Error';
import { ConnectionFailTypeEnum, MixpanelEventTypes, mixpanelTrack } from 'src/utils/mixpanel';

// Display a dialog prompting the user to download and install the Chrome extension
export default function DownloadExtensionDialog() {
  const dispatch = useDispatch();
  const { downloadExtensionDialogOpen } = useSelector((state: IRootState) => state.orders);

  const handleClose = () => dispatch(setOpenDownloadExtensionDialog(false));

  const handlePageReload = () => window.location.reload();

  if (downloadExtensionDialogOpen) {
    mixpanelTrack(
      MixpanelEventTypes.EXTENSION_CONNECTION_FAILED,
      ConnectionFailTypeEnum.EXTENSION_REQUIERED
    );
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={downloadExtensionDialogOpen} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        Extension Required
      </DialogTitle>
      <DialogContent
        sx={{
          typography: 'body2',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <ErrorIcon sx={{ fontSize: '5rem', alignSelf: 'center', mb: '1.5rem' }} color="warning" />
        <DialogContentText sx={{ textAlign: 'center' }}>
          <Typography mb={'1rem'}>
            We're sorry, but it looks like you don't have the Chrome extension installed. To use all
            of our features, you'll need to install it.
          </Typography>

          <Typography mb={'1rem'}>
            <Link
              underline="hover"
              color="primary"
              href="https://chrome.google.com/webstore/detail/cross-listing-poshmark-to/eepbhjeldlodgnndnjofcnnoampciipg"
              target="_blank"
              rel="noopener"
              fontWeight={700}
              px={0.5}
            >
              Click here to download it
            </Link>
          </Typography>

          <Typography>After you install the extension, please reload the page.</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button variant="contained" color="primary" onClick={handlePageReload}>
          Reload Page
        </Button>
      </DialogActions>
    </Dialog>
  );
}
