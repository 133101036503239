// routes
import { PATH_DASHBOARD } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const OPEN_REPLAY_ENV = import.meta.env.VITE_OPENREPLAY_KEY || '';
export const SENTRY_ENV = import.meta.env.VITE_ENV || '';
export const FLAGSMITH_ENVIRONMENT = import.meta.env.VITE_FLAGSMITH_ENV || '';

export const IS_SANDBOX = import.meta.env.VITE_ENV === 'development' ? true : false;

export const HOST_API_KEY =
  import.meta.env.VITE_HOST_API_KEY || 'https://sandbox-api.primelister.com';

export const SUPABASE_KEY = import.meta.env.VITE_SUPABASE_KEY || '';
export const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL || '';

export const MIXPANEL_KEY = import.meta.env.VITE_MIXPANEL_KEY;

// ASSETS
// ----------------------------------------------------------------------
export const DEFAULT_THUMBNAIL_IMAGE = 'https://r2.primelister.com/image_thumbnail.svg';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.root;

export const MOBILE_APP_USER_AGENT = 'PrimeListerMobileApp';

export const EBAY_LISTINGS_LIMIT = 100;

export const EXTENSION_ID_V2 = 'eepbhjeldlodgnndnjofcnnoampciipg';
// LAYOUT
// ----------------------------------------------------------------------

export const CRISP_CLOSE_ICON =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="delete"><path d="M16 5a11 11 0 1 0 11 11A11 11 0 0 0 16 5Zm3.71 13.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L16 17.41l-2.29 2.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l2.3-2.29-2.3-2.29a1 1 0 0 1 1.42-1.42l2.29 2.3 2.29-2.3a1 1 0 0 1 1.42 1.42L17.41 16Z" fill="#2a335c" class="color000000 svgShape"></path></svg>';

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};
