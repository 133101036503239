import React, { useRef, useEffect } from 'react';
import { FormControlLabel, Stack, Switch, Tooltip, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { IRootState } from 'src/store';
import {
  setIsConnectionErrorDialogOpen,
  updateAutomationSettingsRequest,
} from 'src/store/automations/ebay/slices/automationsSlice.ebay';
import { Automation } from '@beta.limited/primelister';
import { IDataForUpdateAutomationSettings } from 'src/pages/automations/AutomationsPage/types/types';

interface IncludePersonalMessageProps {
  ruleIndex: number;
}

const MAX_CHARS = 2000;
const DEFAULT_MESSAGE = "Glad you're interested! Here's a special offer for you.";

const IncludePersonalMessage: React.FC<IncludePersonalMessageProps> = ({ ruleIndex }) => {
  const dispatch = useDispatch();
  const { selectedAutomationOption, automationsSettings, activeClosetAutomationPlatform } =
    useSelector((state: IRootState) => state.ebayAutomations);
  const { activeClosetCredentialsId, hasActiveClosetConnection } = useSelector(
    (state: IRootState) => state.ebayMyShops
  );

  const currentRules =
    automationsSettings?.[Automation.AUTO_SEND_OFFER_TO_LIKERS]?.config?.rules || [];
  const { loading } = useSelector((state: IRootState) => state.ebayAutomations);
  const validationSchema = Yup.object().shape({
    text: Yup.string()
      .max(MAX_CHARS, `Message cannot exceed ${MAX_CHARS} characters`)
      .when('enabled', {
        is: true,
        then: (schema) => schema.required('Message is required when enabled'),
      }),
    enabled: Yup.boolean(),
  });

  const formik = useFormik({
    initialValues: {
      enabled: currentRules[ruleIndex]?.offer?.message?.enabled || false,
      text: currentRules[ruleIndex]?.offer?.message?.text || DEFAULT_MESSAGE,
    },
    validationSchema,
    onSubmit: () => {},
  });

  const updateAutomationSettings = (values: typeof formik.values) => {
    if (values.text.length > MAX_CHARS) {
      return;
    }

    if (values.enabled && !values.text.trim()) {
      return;
    }

    if (!currentRules) {
      console.error('Automation settings are not properly initialized');
      return;
    }

    const toastMessage = `${selectedAutomationOption.displayName} automation configurations successfully updated`;

    const updatedRules = currentRules.map((rule, index) =>
      index === ruleIndex
        ? {
            ...rule,
            offer: {
              ...rule.offer,
              message: {
                enabled: values.enabled,
                text: values.text,
              },
            },
          }
        : rule
    );

    const dataForUpdating: IDataForUpdateAutomationSettings = {
      settings: {
        [Automation.AUTO_SEND_OFFER_TO_LIKERS]: {
          config: {
            rules: updatedRules,
          },
        },
      },
      toastMessage,
      displayName: selectedAutomationOption.displayName,
      activeClosetAutomationPlatform,
      activeClosetCredentialsId,
    };

    dispatch(updateAutomationSettingsRequest(dataForUpdating));
  };

  const handleMessageEnableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(setIsConnectionErrorDialogOpen(true));
      return;
    }

    const isEnabled = event.target.checked;
    formik.setFieldValue('enabled', isEnabled);
    if (isEnabled && !formik.values.text) {
      formik.setFieldValue('text', DEFAULT_MESSAGE);
    }
    updateAutomationSettings({
      enabled: isEnabled,
      text: isEnabled && !formik.values.text ? DEFAULT_MESSAGE : formik.values.text,
    });
  };

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasActiveClosetConnection) {
      dispatch(setIsConnectionErrorDialogOpen(true));
      return;
    }

    const text = event.target.value;
    formik.setFieldValue('text', text);

    if (text.length > MAX_CHARS || (formik.values.enabled && !text.trim())) {
      return;
    }

    if (updateTimer.current) {
      clearTimeout(updateTimer.current);
    }

    updateTimer.current = setTimeout(() => {
      updateAutomationSettings({ ...formik.values, text });
    }, 700);
  };

  const characterCount = formik.values.text.length;

  const updateTimer = React.useRef<NodeJS.Timeout | null>(null);

  useEffect(() => () => {
      if (updateTimer.current) {
        clearTimeout(updateTimer.current);
      }
    }, []);

  return (
    <Stack spacing={2} px={0}>
      <Stack direction="row" alignItems="center">
        <FormControlLabel
          control={
            <Switch
              disabled={loading}
              checked={formik.values.enabled}
              onChange={handleMessageEnableChange}
            />
          }
          sx={{
            mr: 0,
          }}
          label="Include personal message"
        />
        <Tooltip
          enterTouchDelay={0}
          leaveTouchDelay={3000}
          arrow
          title="ℹ️ Enable this option to include a personal message with your offers."
          placement="top"
        >
          <HelpOutlineIcon sx={{ color: '#637381', fontSize: '16px !important', ml: 0.5 }} />
        </Tooltip>
      </Stack>

      {formik.values.enabled && (
        <Stack
          sx={{
            marginLeft: '48px !important',
            marginTop: '12px !important',
          }}
        >
          <TextField
            multiline
            rows={4}
            disabled={loading}
            value={formik.values.text}
            onChange={handleMessageChange}
            onBlur={formik.handleBlur}
            error={Boolean(formik.errors.text || characterCount > MAX_CHARS)}
            placeholder="Enter your message here..."
            sx={{
              '& .MuiOutlinedInput-root': {
                bgcolor: formik.errors.text || characterCount > MAX_CHARS ? '#FFF2EF' : '#eeeff1',
              },
            }}
          />
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 0.5 }}
          >
            <Typography
              variant="caption"
              color="error"
              sx={{
                flex: 1,
                visibility: formik.errors.text || characterCount > MAX_CHARS ? 'visible' : 'hidden',
              }}
            >
              {characterCount > MAX_CHARS
                ? `Message cannot exceed ${MAX_CHARS} characters`
                : formik.errors.text || ' '}
            </Typography>
            <Typography
              variant="caption"
              align="right"
              color={characterCount > MAX_CHARS ? 'error' : 'textSecondary'}
            >
              {MAX_CHARS - characterCount} characters remaining
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default IncludePersonalMessage;
