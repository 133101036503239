// @mui
import { Box, BoxProps, useMediaQuery } from '@mui/material';
// config
import { HEADER } from '../../config';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector , useDispatch } from 'react-redux';
import { IRootState } from 'src/store';
import RatingPopup from 'src/components/rating-popup/RatingPopup';
import { setShowMobileAppReviewDialog } from 'src/store/automations/slices/automationsSlice';
import { useEffect } from 'react';
import { PATH_AUTOMATIONS, PATH_DASHBOARD } from 'src/routes/paths';
import { ConnectionStatus } from 'src/store/automations/slices/myClosetSlice';
import ClosetSelectionDropDown from 'src/pages/automations/MyClosetPage/components/ClosetSelectionDropDown';
import { useLocation } from 'react-router-dom';
import { useAutomationPlatform, AutomationPlatform } from 'src/hooks/useAutomationPlatform';

// ----------------------------------------------------------------------

const SPACING = 8;

interface ILayoutMainProps extends BoxProps {
  hasAlert: boolean;
}

export default function Main({ children, hasAlert }: ILayoutMainProps) {
  const isMobile = useResponsive('down', 'sm');
  const navbarMatches = useMediaQuery('(min-width:1349px)');
  const isInventoryPage = window.location.pathname.includes('inventory');
  const isTaskPage = window.location.pathname.includes('task');
  const isPricingPage = window.location.pathname.includes('subscription');
  const hasDataGridInit = isInventoryPage || isTaskPage;
  const { isPrimeListerMobileApp } = useSelector((state: IRootState) => state.home);
  const { isInFullscreenMode } = useSelector((state: IRootState) => state.inventory);
  const { isOrderPageInFullscreenMode } = useSelector((state: IRootState) => state.orders);
  const dispatch = useDispatch();
  const location = useLocation();
  const { platform, states } = useAutomationPlatform();

  const { hasCloset, closetList } = states.myShops;
  const isFullscreenMode = isInFullscreenMode || isOrderPageInFullscreenMode;
  const { isUserBoardedMultipleClosets } = states.automations;

  const navlessPadding = !isPricingPage && hasAlert ? `60px` : '100px';
  const isDesktop = useResponsive('up', 'sm');
  const isSmallerScreen = useMediaQuery('(max-width:375px)');

  const isPage = (path: string) => window.location.pathname.includes(path);
  const isAutomationsRelatedPage = () =>
    [
      PATH_AUTOMATIONS.listings,
      PATH_AUTOMATIONS.activity,
      PATH_AUTOMATIONS.automations,
      PATH_AUTOMATIONS.statistics,
      PATH_AUTOMATIONS.ebayRoot,
      PATH_AUTOMATIONS.ebayActivity,
      PATH_AUTOMATIONS.ebayMyShops,
      PATH_AUTOMATIONS.ebayAutomations,
    ].some(isPage);

  const hasMultipleClosets =
    closetList.filter((closet) => closet?.status !== ConnectionStatus.Removed).length > 1;

  const shouldDisplayClosetSelectionMenu =
    hasMultipleClosets && isAutomationsRelatedPage() && isMobile;

  const notOnMobileInGeneral = !isMobile;
  const isAlertVisibleAndNotPricingPage = !isPricingPage && hasAlert;
  const isAccountPage =
    window.location.pathname.includes(PATH_AUTOMATIONS.account) ||
    window.location.pathname.includes(PATH_AUTOMATIONS.ebayAccount);
  const isMyListingsPage = window.location.pathname.includes(PATH_AUTOMATIONS.listings);

  const calculateNavPadding = () => {
    if (isAlertVisibleAndNotPricingPage) {
      return notOnMobileInGeneral ? '108px' : isMyListingsPage ? '0px' : '60px';
    } else if (notOnMobileInGeneral) {
      return '148px';
    } else if (isMyListingsPage && (isMobile || isPrimeListerMobileApp)) {
      return shouldDisplayClosetSelectionMenu ? '124px' : '76px';
    } else {
      return isAccountPage ? '100px' : shouldDisplayClosetSelectionMenu ? '60px' : '80px';
    }
  };

  const navPadding = calculateNavPadding();

  const paddingTop = isFullscreenMode ? '16px' : navbarMatches ? navlessPadding : navPadding;
  const paddingBottom = hasDataGridInit
    ? isFullscreenMode
      ? '16px'
      : `24px`
    : `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`;
  const maxHeight = hasDataGridInit ? 'max-content !important' : null;
  const hasHorizontalPadding =
    isDesktop ||
    !window.location.pathname.includes(PATH_AUTOMATIONS.listings) ||
    isPrimeListerMobileApp;

  const handleRatingPopupClose = () => {
    dispatch(setShowMobileAppReviewDialog(false));
  };

  return (
    <Box
      component="main"
      sx={{
        px: hasHorizontalPadding || isAlertVisibleAndNotPricingPage ? 2 : 0,
        pt: paddingTop,
        pb: paddingBottom,
        maxHeight: maxHeight,
      }}
    >
      {shouldDisplayClosetSelectionMenu && (
        <Box
          sx={{
            width: '100%',
            height: '48px',
            display: 'flex',
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            zIndex: 2,
            backgroundColor: '#fff',
            alignItems: 'center',
            p: '3px 16px',
            borderBottom: '1px solid #EBECED',
            boxShadow: '0px 8px 16px 0px rgba(145, 158, 171, 0.16)',
          }}
        >
          <ClosetSelectionDropDown />
        </Box>
      )}
      {children}
      <RatingPopup onClose={handleRatingPopupClose} />
    </Box>
  );
}
